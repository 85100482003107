<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
<div v-if="loading==false">
  <div  v-if="cart_items.length ">
<div v-for="(order_item, index) in cart_items" :key="order_item.id">
 
  <b-row v-if="user.stl_files && order_item.size"  >
    
    <b-card class="mb-1 col-md-12" title="3D Files">
       <b-card-text>
        <!-- <img :src="photos[index]" width="120" /><br /> -->

        <b-row v-if="user.stl_files">

          <template v-if="order_item.size!=null">
            
            <template v-if="order_item.size=='s' || order_item.size=='l' || order_item.size=='xs' || order_item.size=='m' || order_item.size=='xl' ">
              <template v-if="cart_items.length">

                
                <b-card v-if="order_item.glass.id!=24" class="mb-1 col-sm mini" title="Face" >
                  <img class="img-fluid" src="@/assets/images/frame.png"  />
                  <br /> <br />
                  <center>
                  <a @click="downloadFrame(order_item)">
                    <b-button variant="outline-primary">Download</b-button>
                  </a></center>
                </b-card>

                <b-card v-else class="mb-1 col-sm mini" title="Face" >
                  <img class="img-fluid" src="@/assets/images/frame.png"  />
                  <br /> <br />
                  <center>
                  <a @click="downloadFrameSingle(order_item)">
                    <b-button variant="outline-primary">Download</b-button>
                  </a></center>
                </b-card>

                <div v-else class="mb-1 col-sm mx-1 mini" ></div>

                <b-card v-if="order_item.glass.id!=24" class="mb-1 col-sm mx-1 mini" title="Temple Left" >
                  <img class="img-fluid" src="@/assets/images/templel.png"  />
                  <br /><br />
                  <center>
                  <a :href="'https://api.sens-eye.fr/stl-files/'+calculateStlTempleL(order_item)" download target="_blank">
                    <b-button variant="outline-primary">Download</b-button>
                  </a>
                </center>
                </b-card>
                <div v-else class="mb-1 col-sm mx-1 mini" ></div>
                
                <b-card v-if="order_item.glass.id!=24" class="mb-1 col-sm mx-1 mini" title="Temple Right" >
                  <img class="img-fluid" src="@/assets/images/templer.png"  />
                  <br /><br />
                  <center>
                  <a :href="'https://api.sens-eye.fr/stl-files/'+calculateStlTempleR(order_item)" download target="_blank">
                    <b-button variant="outline-primary">Download</b-button>
                  </a>
                </center>
                </b-card>
                <div v-else class="mb-1 col-sm mx-1 mini" ></div>

                <b-card v-if="order_item.glass.id!=24" class="mb-1 col-sm black mini" title="Download all the files" >
                  <img class="img-fluid" src="@/assets/images/all.png"  />
                  <br /><br />
                  <center>
                    <a @click="downloadAll(order_item)" download target="_blank">
                    <b-button variant="outline-light">Download all</b-button>
                  </a>
                  </center>
                </b-card>
                <div v-else class="mb-1 col-sm mx-1 mini" ></div>
              </template>
            </template>
          </template>
        </b-row>
     
      </b-card-text>
    </b-card>

    <ScanPopup :scan_id="currentScan" v-if="displayScanPopup" />
    <PrescriptionPopup :prescription_id="currentPrescription" v-if="displayPrescriptionPopup" />
</b-row>
  <b-row >
        <b-card class="mb-1 col-sm" :title="'Order #'+order.id">
          <table class="table">
            
              <tr >
                  <td>Product name:</td>
                  <td> <span v-if="order_item.product_variation_item">
                  {{order_item.glass.name}} ({{order_item.product_variation_item.name}})
                </span>
                <span v-else>
                  {{order_item.glass.name}}
                </span></td>
              </tr>
              <tr >
                  <td>Quantity:</td>
                  <td>{{order_item.quantity}}</td>
              </tr>
           
            <tr>
                <td>Date:</td>
                <td>{{order.date}}</td>
            </tr>
            <tr>
                <td>Buyer:</td>
                <td>{{order.user.name}} {{order.user.surname}}</td>
            </tr>
            <tr v-if="order.address">
                <td>Phone:</td>
                <td>{{order.address.phone}}</td>
            </tr>
            <tr v-if="order.address">
                <td>Delivery address:</td>
                <td>{{order.address.address1}} - {{order.address.address2}}<br />
            {{order.address.city}} {{order.address.postcode}}<br />
            {{order.address.country}}</td>
            </tr>
            <tr>
                <td>Status:</td>
                <td>
                  <b-badge v-if="order.status=='dispatched'" variant="success">Dispatched</b-badge>
                  <b-badge v-else-if="order.status=='scanned'" variant="success">Scans done</b-badge>
                  <b-badge v-else-if="order.status=='completed'" variant="warning">Completed</b-badge>
                  <b-badge v-else-if="order.status=='printing'" variant="warning">Printing</b-badge>
                  <b-badge v-else-if="order.status=='delivered'" variant="success">Delivered</b-badge>
                  <b-badge v-else-if="order.status=='failed'" variant="danger">Failed</b-badge>
                  <b-badge v-else-if="order.status=='canceled'" variant="danger">Canceled</b-badge>
                
                  <b-badge v-else variant="danger">error</b-badge>

                

                </td>
            </tr>
            <tr v-if="order.promotion!=null">
                <td>Promotion:</td>
                <td>-{{order.promotion.amount}}%</td>
            </tr>
            <tr >
                <td>Total:</td>
                <td>
                  <!-- <span v-if="order.promotion!=null">{{order.amount - ((order.amount/100)*order.promotion.amount).toFixed(2)}}€</span>
                  <span v-else>{{order.amount}}€</span> -->
                  On demand
                </td>
            </tr>

            <tr>
                <td>Note:</td>
                <td>
                   <b-form-group
                      
                      label-for="basicInput"
                    >
                    
                      <b-form-input
                      required
                        id="basicInput"
                        v-model="order.note"
                        
                        placeholder=""
                  
                      />
                
                    
                    </b-form-group>
                </td>
              </tr>

          
            

          </table>
          <br />
          <b-button @click="saveNote()">Save note</b-button>
          <div v-if="displayStatusSelection==false && order.status!='printing'  && order.status!='canceled'">
            <br />
            <b-button  @click="displayStatusSelection=true">Cancel order</b-button>
          </div>
          <div v-else-if="displayStatusSelection==true">
        
          <br />
          <b-button class="btn-danger"  @click="updateStatus()">Confirm cancel</b-button>  <b-button  @click="displayStatusSelection=false">Annuler</b-button>
          </div>
          
         
        </b-card>

        <b-card class="mb-1 mx-1 col-sm" title="Customization informations">
         
            <template >
              <table class="table">
              <tr v-if="order_item.scan!=null">
                <td>Scan : </td>
                  <td>{{order_item.scan.name}}</td>
              </tr>
              <tr v-else-if="order_item.size!=null">
                <td>Size : </td>
                  <td>{{order_item.size}}</td>
             
              </tr>



              <template v-for="order_item_customization in order_item.order_item_customizations">
                  <tr v-if="order_item_customization.model_part.type=='color' && order_item_customization.model_part.render_type=='face'">
                    <td>Color {{order_item_customization.model_part.name}}:</td>
                    <td>{{order_item_customization.color.name}}</td>
                  </tr>
                  <template v-else-if="order_item_customization.model_part.type=='texture_color' && order_item_customization.model_part.render_type=='face'">

                    <tr >
                      <td>Color {{order_item_customization.model_part.name}}:</td>
                      <td> {{order_item_customization.color.name}}</td>
                    </tr>
                   

                   
                  
                  </template>
                <!-- <span v-if="order_item_customization.model_part.render_type=='lense'">Teinte de verres : {{order_item_customization.tint ? order_item_customization.tint.name : "Aucune"}}<br /></span> -->
                </template>

                <template v-for="order_item_customization in order_item.order_item_customizations">
                  <tr v-if="order_item_customization.model_part.type=='color' && order_item_customization.model_part.render_type=='branch'">
                    <td>Color {{order_item_customization.model_part.name}}:</td>
                    <td>{{order_item_customization.color.name}}</td>
                  </tr>
                  <template v-else-if="order_item_customization.model_part.type=='texture_color' && order_item_customization.model_part.render_type=='branch'">

                    <tr >
                      <td>Color {{order_item_customization.model_part.name}}:</td>
                      <td> {{order_item_customization.color.name}}</td>
                    </tr>
                   

                   
                  
                  </template>
                <!-- <span v-if="order_item_customization.model_part.render_type=='lense'">Teinte de verres : {{order_item_customization.tint ? order_item_customization.tint.name : "Aucune"}}<br /></span> -->
                </template>

                <tr v-if="order_item.tint">
                <td>Lens tint:</td>
                <td>{{order_item.tint.name}}</td>
              </tr>


            </table>
            <br /><br /><br />
            <table class="table">

                <template v-for="order_item_customization in order_item.order_item_customizations">
                  
                  <template v-if="order_item_customization.model_part.type=='texture_color'">

                    <tr v-if="order_item_customization.texture">
                      <td>Texture {{order_item_customization.model_part.name}}: </td>
                      
                      <td>{{order_item_customization.texture.name}} 

                        <span v-if="order_item_customization.texture_style">
                         
                         ({{order_item_customization.texture_style.name}})
                        </span>

                      </td>
                      
                    </tr>
                    <tr v-else>
                      <td>Texture {{order_item_customization.model_part.name}}:</td> <td>Sans</td>
                      
                    </tr>
                  
                   

                   
                  
                  </template>
                <!-- <span v-if="order_item_customization.model_part.render_type=='lense'">Teinte de verres : {{order_item_customization.tint ? order_item_customization.tint.name : "Aucune"}}<br /></span> -->
                </template>


<!-- 
              
                <template v-for="order_item_customization in order_item.order_item_customizations">
                  <tr v-if="order_item_customization.model_part.type=='color'">
                    <td>Color {{order_item_customization.model_part.name}}:</td>
                    <td>{{order_item_customization.color.name}}</td>
                  </tr>
                  <template v-else-if="order_item_customization.model_part.type=='texture_color'">

                    <tr >
                      <td>Color {{order_item_customization.model_part.name}}:</td>
                      <td> {{order_item_customization.color.name}}</td>
                    </tr>
                    <tr v-if="order_item_customization.texture">
                      <td>Texture {{order_item_customization.model_part.name}}: </td>
                      
                      <td>{{order_item_customization.texture.name}}</td>
                      
                    </tr>
                    <tr v-else>
                      <td>Texture {{order_item_customization.model_part.name}}:</td> <td>Sans</td>
                      
                    </tr>
                    <tr v-if="order_item_customization.texture_style">
                      <td>Texture position {{order_item_customization.model_part.name}}:</td> 
                      <td>{{order_item_customization.texture_style.name}}</td>
                    </tr>

                   
                  
                  </template>
                 <span v-if="order_item_customization.model_part.render_type=='lense'">Teinte de verres : {{order_item_customization.tint ? order_item_customization.tint.name : "Aucune"}}<br /></span> 
                </template> -->
              
              
              <!-- <template v-if="!order_item.order_item_customizations">

                <tr v-if="order_item.glass_type">
                  <td>Lens type:</td> 
                  <td>{{order_item.glass_type.name}}</td>

                </tr>
                <tr v-if="order_item.glass_option">
                  <td>Option de verre :</td>
                  <td>{{order_item.glass_option.name}}</td>
                </tr>
                <tr v-if="order_item.color">
                  <td>Couleur : </td>
                  <td>{{order_item.color.name}}</td>
                </tr>
              
                <tr v-if="order_item.branch">
                  <td>Branche : </td>
                  <td>{{order_item.branch.name}}</td>
                </tr>
                <tr v-if="order_item.branch_color">
                  <td>Couleur de Branche : </td>
                  <td>{{order_item.branch_color.name}}</td>
                  
                </tr>
                <tr v-if="order_item.branch_texture">
                  <td>Texture de Branche : </td>
                  <td>{{order_item.branch_texture.name}}</td>
                </tr>
                <tr v-if="order_item.texture">
                  <td>Texture :</td>
                  <td>
                    {{order_item.texture.name}}
                  </td>

                </tr>
                <tr v-if="order_item.texture_style">
                  <td>Style de texture : </td>
                  <td>{{order_item.texture_style.name}}</td>
                </tr>
                <tr v-if="order_item.refinement">
                  <td>Affinement : </td>
                  <td>{{order_item.refinement.name}}</td>
                </tr>
                <tr v-if="order_item.processing">
                  <td>Traitement : </td>
                  <td>{{order_item.processing.name}}</td>
                </tr>
              </template> -->

            
              <tr v-if="order_item.engraving_text">
                <td>Engraving : </td>
                  <td>{{order_item.engraving_text}}</td>
                  
                  </tr>
              <tr v-else>
                <td>Engraving : </td>
                  <td>no</td>
                  </tr>

             
              <tr v-if="order_item.prescription!=null">
                <td>Prescription :</td>
                  <td>{{order_item.prescription.name}} (<a @click="displayPrescription(order_item.prescription.id)">Voir</a>)</td>
              </tr>
             
          
          </table>
        </template>
        </b-card>

        <b-card class="mb-1  col-sm" >
          <template >
          <CartImage :cart_item="order_item" />
          </template>
        </b-card>
   

  </b-row>
</div>
</div>
</div>
</b-overlay>
</template>

<script>
import CartImage from "./CartImageComponent.vue";
import {BModal, BButton,BOverlay, VBModal, BRow, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Auth} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ScanPopup from '../../components/ScanPopup.vue'
import PrescriptionPopup from '../../components/PrescriptionPopup.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import emitter from 'tiny-emitter/instance'
import { saveAs } from 'file-saver';

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    CartImage,
    BAvatar,
    BOverlay,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    BRow,
    ScanPopup,
    PrescriptionPopup
  },
  data() {
    return {
      order:null,
      displayStatusSelection:false,
      statusSelected:"canceled",
      statusOptions:['dispatched','failed','delivered','completed', 'canceled', 'printing'],
      pageLength: 10,
      dir: false,
      photos:null,
      user:null,
      cart_items:[],
      currentScan:null,
      currentPrescription:null,
      displayPrescriptionPopup:false,
      displayScanPopup:false,
      loading:true,
      trackingNumber:''
    }
  },
  methods:{
    calculateStl(order_item)
    {
    
      let url = 'https://api.sens-eye.fr/stl-files/'+order_item.glass.file1+'_frame'

      for(let i=0;i<order_item.order_item_customizations.length;i++)
      {
        if(order_item.order_item_customizations[i].model_part.render_slug=="frame")
        {
          if(order_item.order_item_customizations[i].texture)
          {
            url+='_texturestyle'+order_item.order_item_customizations[i].texture.id
           // url+='-texturestyle'+order_item.order_item_customizations[i].texture.id
          }
          else
          {
            url+='_texturestyle4'
            //url+='-texturestyle4'
          }
          if(order_item.order_item_customizations[i].texture_style)
          {
            url+='_textureposition'+order_item.order_item_customizations[i].texture_style.id
            //url+='-textureposition'+order_item.order_item_customizations[i].texture_style.id
          }
          else
          {
            url+='_textureposition0'
            //url+='-textureposition0'
          }
          
        }
      }
      
      url+='_'+order_item.size
      
      url+='.stl'

      return url
    },
    calculateStlFrame(order_item)
    {
      let url = order_item.glass.file1+'_frame'
      //let url = order_item.glass.file1

      for(let i=0;i<order_item.order_item_customizations.length;i++)
      {
        if(order_item.order_item_customizations[i].model_part.render_slug=="frame")
        {
          if(order_item.order_item_customizations[i].texture)
          {
            url+='_texturestyle'+order_item.order_item_customizations[i].texture.id
            //url+='-texturestyle'+order_item.order_item_customizations[i].texture.id
          }
          else
          {
            url+='_texturestyle4'
            //url+='-texturestyle4'
          }
          if(order_item.order_item_customizations[i].texture_style)
          {
            url+='_textureposition'+order_item.order_item_customizations[i].texture_style.id
            //url+='-textureposition'+order_item.order_item_customizations[i].texture_style.id
          }
          else
          {
            url+='_textureposition0'
            //url+='-textureposition0'
          }
          
        }
      }
      
      url+='_'+order_item.size
      
      url+='.stl'

      return url
    },
    calculateStlTempleR(order_item)
    {
    
      let url = order_item.glass.file1+'_templer'
      //let url = order_item.glass.file1

      for(let i=0;i<order_item.order_item_customizations.length;i++)
      {
        if(order_item.order_item_customizations[i].model_part.render_slug=="temples")
        {
          if(order_item.order_item_customizations[i].texture)
          {
            url+='_texturestyle'+order_item.order_item_customizations[i].texture.id
            //url+='-texturestyle'+order_item.order_item_customizations[i].texture.id
          }
          else
          {
            url+='_texturestyle4'
           // url+='-texturestyle4'
          }
         
          
        }
      }
      
      //url+='-TempleR'
      url+='_'+order_item.size
      
      url+='.stl'

      return url
    },
    calculateStlTempleL(order_item)
    {
    
      let url = order_item.glass.file1+'_templel'
      //let url = order_item.glass.file1

      for(let i=0;i<order_item.order_item_customizations.length;i++)
      {
        if(order_item.order_item_customizations[i].model_part.render_slug=="temples")
        {
          if(order_item.order_item_customizations[i].texture)
          {
         
            url+='_texturestyle'+order_item.order_item_customizations[i].texture.id
           //url+='-texturestyle'+order_item.order_item_customizations[i].texture.id
          }
          else
          {
            url+='_texturestyle4'
            //url+='-texturestyle4'
          }
        
          
        }
      }
     
      //url+='-TempleL'
      url+='_'+order_item.size
      
      url+='.stl'

      return url
    },
    async downloadFile(url, filename) {
      try {
        const response = await fetch(url, {
          
          mode:"no-cors"
          
        });

        console.log('OK')
        console.log(response)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        saveFile(blobUrl, filename);
        URL.revokeObjectURL(blobUrl);
      } catch (err) {
        console.error("Error in fetching and downloading file:", err);
      }
    },
    downloadFrameSingle(order_item)
    {
      let url3="https://api.sens-eye.fr/api/download-zip-single/"+this.calculateStlFrame(order_item);
      saveAs(url3, "frame.stl");
      this.setDownloaded()
    },
    downloadFrame(order_item)
    {
      let url3=this.calculateStl(order_item);
      saveAs(url3, "frame.stl");
      this.setDownloaded()
    },
    downloadAll(order_item)
    {


      let url1='https://api.sens-eye.fr/api/download-zip/'+this.calculateStlTempleL(order_item)+'/'+this.calculateStlTempleR(order_item)+'/'+this.calculateStlFrame(order_item)
     
      saveAs(url1, "files.zip");

      // //saveAs(blob, "hello world.txt");
       //saveAs(url1, "temple-l.stl");
      // saveAs(url2, "temple-r.stl");
      // saveAs(url3, "frame.stl");
      
      
      // window.open(url1);
      // window.open(url2);
      // window.open(url3);
      this.setDownloaded()

    },
    async setDownloaded() {
      this.loading=true
      let params=this.order
      params.files_downloaded=true
      await APIRequest.update('orders',this.order.id,params)
      this.loading=false
    },
    async saveNote() {
      this.loading=true
      let params=this.order
      await APIRequest.update('orders',this.order.id,params)
      this.loading=false
    },
    displayScan(id) {
      this.currentScan=id
      this.displayScanPopup=true
    },
    displayPrescription(id) {
      this.currentPrescription=id
      this.displayPrescriptionPopup=true
    },
    async getOrder() {
      this.loading=true
      let order=await APIRequest.detail('orders',this.$route.params.id)
     
      this.order=order
    
      //this.statusSelected=this.order.status
        console.log('on a lorder')
    console.log(this.order)
      if(!this.order.user)
      {
        if(this.order.cart!=null) {
          if(this.order.cart.user!=null) {
            this.order.user={}
            this.order.user.name=this.order.cart.user.name
            this.order.user.surname=this.order.cart.user.surname
          }
          else {
            this.order.user={}
            this.order.user.name=''
            this.order.user.surname='Inconnu'
          }
        }
        else {
          this.order.user={}
          this.order.user.name=''
          this.order.user.surname='Inconnu'
        }
       
      }
      if(!this.order.status)
      {
        if(this.order.promotion!=null) {
          if(this.order.promotion.amount==100) {
            this.order.status="completed"
          }
        }
        else if(this.order.cart!=null) {
          if(this.order.cart.promotion!=null) {
            if(this.order.cart.promotion.amount==100) {
              this.order.status="completed"
            }
          }
        }
      }
     
      this.photos=[]

      if(this.order.cart!=null) {

        for(let i=0;i<this.order.cart.cart_items.length;i++)
        {
          if(this.order.cart.cart_items[i].product_variation_item!=null)
          {
            var photos=this.order.cart.cart_items[i].product_variation_item.photos;
          }
          else
          {
            var photos=this.order.cart.cart_items[i].glass.photos;
          }
          if(photos)
          {
            let photo=photos[0];   
            if(photo!='')
            {
              this.photos[i]=photo
            }
            else{
              this.photos[i]=null
            }
          }
          
        }
        this.cart_items=this.order.cart.cart_items
        console.log('cart items')
        console.log(this.cart_items)

      }
      else {
        for(let i=0;i<this.order.order_items.length;i++)
        {
          if(this.order.order_items[i].product_variation_item!=null)
          {
            var photos=this.order.order_items[i].product_variation_item.photos;
          }
          else
          {
            var photos=this.order.order_items[i].glass.photos;
          }
          if(photos)
          {
            let photo=photos[0];   
            if(photo!='')
            {
              this.photos[i]=photo
            }
            else{
              this.photos[i]=null
            }
          }
        }
        this.cart_items=this.order.order_items
      }
      this.loading=false
     
    },
    async updateTracking() {
      this.loading=true
      let datas={}
      
      data.tracking_number = this.trackingNumber
      await APIRequest.update('orders',this.order.id,datas)


    
      await this.getOrder()
      this.loading=false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Tracking number updated !',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    async updateStatus() {
      this.loading=true
      console.log('status seleced')
      console.log(this.statusSelected)
      if(this.statusSelected!=this.order.status)
      {
        let datas=this.order
        datas.status=this.statusSelected
        await APIRequest.update('orders',this.order.id,datas)
        await this.getOrder()
      }
      this.loading=false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Status updated !',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    async sendInvoiceMail() {
      this.loading=true
      let email = await APIRequest.send_email('invoice',this.order.id)
      this.loading=false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Invoice mail has been sent!',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    async sendTrackingMail() {
      this.loading=true
      let email = await APIRequest.send_email('tracking',this.order.id)
      this.loading=false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Tracking mail has been sent!',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    }
    
  },
  watch:{
    
  },
  async mounted()
  {
    this.loading=true
    emitter.on('hide-popup-scan', function () {
      this.displayScanPopup=false
    }, this);
    emitter.on('hide-popup-prescription', function () {
      this.displayPrescriptionPopup=false
    }, this);

    await this.getOrder()
    this.loading=true
    this.user=await Auth.get_user_with_id(localStorage.id_user_senseye_auth)
  
    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
